<div class="pmLogo" *ngIf="liveDataModel.playing">
  <img src="assets/logo.png" width="130px">
</div>
<app-youtube-player
  *ngIf="configLiveVideo.platform=='youtube'"
  [configLiveVideo]="configLiveVideo"
  [liveDataModel]="liveDataModel"
  (dataEvent)="onChangeDataEvent($event)"
>


</app-youtube-player>
<div class="wrapper">
  <section *ngFor="let like of likes">
    <img *ngIf="like.img" [src]="like.img" class="circular--square photo like hearth x1">
    <mat-icon class="like hearth x1">favorite</mat-icon>
<!--    <mat-icon class="like hearth x2">favorite</mat-icon>-->
    <mat-icon class="like hearth x3">favorite</mat-icon>
  </section>
</div>

