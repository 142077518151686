import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {environment as env} from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  constructor(
    private httpClient: HttpClient
  ) {
  }

  getConfig(config: any = null): Observable<any> {
    const filters = {"ean":"123456789abcdefg","sku":"CN0645","retail":"hp.com"};
    return this.httpClient.post<any>(`${env.HOST_API}`, {
      ...filters
    });
  }


}

