import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LivevideoComponent} from './livevideo/livevideo.component';
import {ActionbarComponent} from './actionbar/actionbar.component';
import {LivechatComponent} from './livechat/livechat.component';
import {LivechatButtonComponent} from './livechat-button/livechat-button.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BroadcastService} from "./services/broadcast.service";
import {YoutubePlayerComponent} from './livevideo/youtube-player/youtube-player.component';
import {SafeurlPipe} from "./pipes/SafeUrl.pipe";
import {HttpClientModule} from "@angular/common/http";
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from "@angular/material/icon";
import {MatSliderModule} from "@angular/material/slider";
import {FormsModule} from "@angular/forms";
import {LazyLoadScriptService} from "./services/lazy-load-script.service";
import {ProductsComponent} from './products/products.component';
import {MatCardModule} from "@angular/material/card";
import {
  SocialLoginModule,
  SocialAuthServiceConfig,
} from "@abacritt/angularx-social-login"
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';

const config: SocketIoConfig = { url: 'https://pmlive.staging.alquimio.cloud' };

import {GoogleLoginProvider} from '@abacritt/angularx-social-login';

@NgModule({
  declarations: [
    AppComponent,
    LivevideoComponent,
    ActionbarComponent,
    LivechatComponent,
    LivechatButtonComponent,
    YoutubePlayerComponent,
    SafeurlPipe,
    ProductsComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatButtonModule,
    MatIconModule,
    MatSliderModule,
    FormsModule,
    MatCardModule,
    SocialLoginModule,
    SocketIoModule.forRoot(config)

  ],
  providers: [
    BroadcastService,
    LazyLoadScriptService,
    {provide: Window, useValue: window},
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider('50781332374-v5rtf7p51hrq2p8l95pvu3e5qalb83kn.apps.googleusercontent.com', {
              scopes: 'profile email',
              //plugin_name: 'test'
            }),
          },
        ],
      } as SocialAuthServiceConfig,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

let apiLoaded = false;
