import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LazyLoadScriptService} from "../services/lazy-load-script.service";
import {BroadcastService} from "../services/broadcast.service";
import {LiveDataModel} from "../LiveDataModel";
import {
  SocialAuthService,
  GoogleLoginProvider,
  SocialUser,
} from "@abacritt/angularx-social-login"

@Component({
  selector: 'app-livechat',
  templateUrl: './livechat.component.html',
  styleUrls: ['./livechat.component.scss']
})
export class LivechatComponent implements OnInit, AfterViewInit {
  @Input() liveDataModel: any;
  @Input() configLiveChat: any;
  @Output() dataEvent = new EventEmitter<any>();

  isLogged: boolean = false;
  socialUser!: SocialUser;
  isLoggedin?: boolean;


  constructor(
    private lazyLoadService: LazyLoadScriptService,
    private broadCastService: BroadcastService,
    private socialAuthService: SocialAuthService,
    public window: Window
  ) {

  }

  ngAfterViewInit() {
    setTimeout(() => {
      // @ts-ignore
      document.getElementById("contenedorLiveChat").scrollTop = 300;
    }, 1000)

  }


  ngOnInit(): void {
    var url = window.location.href;
    var domain = url.replace('http://', '').replace('https://', '').split(/[/?#]/)[0];
    domain = domain.replace(':4200', '');
    this.configLiveChat.iframeUrl = `https://www.youtube.com/live_chat?v=${this.configLiveChat.videoId}&embed_domain=${domain}`;

    this.socialAuthService.authState.subscribe((user) => {
      this.liveDataModel.socialUser = user;
      this.liveDataModel.isLoggedin = true;
      this.broadCastService.broadcast({type: 'changeSize'})
      this.sendDataEvent();
    }, (
      (error) => console.log('err on auth', error)))
  }


  loginWithGoogle(): void {

    this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID, {"scope" : "profile email"}).then((r) => {

      setTimeout(() => {
        // @ts-ignore
        document.getElementById("contenedorLiveChat").scrollTop = 300;
      }, 1000);
    },(err)=>{
      debugger;
      console.log(err);
    })
  }

  logOut(): void {
    this.socialAuthService.signOut();
  }

  handleClick() {
    this.liveDataModel.livechatOpen = !this.liveDataModel.livechatOpen;
    this.broadCastService.broadcast({type: 'changeSize'})
    this.sendDataEvent();
  }

  sendDataEvent() {
    this.dataEvent.emit(this.liveDataModel);
  }

}
