


<div class="actionBar" id="actionBar" >
  <button mat-icon-button aria-label="TEST" (click)="clickProducts()">
    <mat-icon [class.active] = "liveDataModel.showProducts ===true" >storefront</mat-icon>
  </button>
  <button mat-icon-button  aria-label="TEST"  (mouseover)="activeVolumenBar()" *ngIf="!liveDataModel.isMobile"  [ngClass]="{'unmuteme': (liveDataModel.mute == 1 || liveDataModel.mute)}" >
    <div class="hoverVolumen" *ngIf="displayVolumen" >
      <mat-slider
        class="example-margin"
        [disabled]="false"
        [max]="100"
        [min]="0"
        [step]="1"
        [thumbLabel]="true"
        [(ngModel)]="liveDataModel.volumen"
        [vertical]="true"
        (change)="changeVolume($event)"
        aria-labelledby="example-name-label">
      </mat-slider>
    </div>
    <mat-icon [style.display]="liveDataModel.volumen !==0 && ! liveDataModel.mute ? 'block' : 'none'" (click)="clickSound()">volume_up</mat-icon>
    <mat-icon [style.display]="liveDataModel.volumen==0 || liveDataModel.mute ? 'block' : 'none'" (click)="clickSound()" >volume_off</mat-icon>

  </button>

  <button mat-icon-button  aria-label="TEST"  (click)="clickSound()" *ngIf="liveDataModel.isMobile"  [ngClass]="{'unmuteme': (liveDataModel.mute == 1 || liveDataModel.mute)}">
    <mat-icon [style.display]="liveDataModel.volumen !==0 && ! liveDataModel.mute ? 'block' : 'none'">volume_up</mat-icon>
    <mat-icon [style.display]="liveDataModel.volumen==0 || liveDataModel.mute ? 'block' : 'none'">volume_off</mat-icon>
  </button>

  <button mat-icon-button  aria-label="TEST" (click)="clickLike()">
    <mat-icon [style.display]="enabledLike ? 'block' : 'none'">favorite_border</mat-icon>
    <mat-icon [style.display]="!enabledLike ? 'block' : 'none'" class="like">favorite</mat-icon>
  </button>
  <button mat-icon-button  aria-label="TEST" (click)="clickLivechat()">
    <mat-icon [style.display]="liveDataModel.livechatOpen ? 'block' : 'none'">comment</mat-icon>
    <mat-icon [style.display]="!liveDataModel.livechatOpen ? 'block' : 'none'">comments_disabled</mat-icon>
  </button>
</div>

