import {AfterViewInit, Component, OnInit} from '@angular/core';
import {LivechatButtonComponent} from "./livechat-button/livechat-button.component";
import {ConfigService} from "./services/config.service";
import {LiveDataModel} from "./LiveDataModel";
import {animate, style, transition, trigger} from "@angular/animations";
import {BroadcastService} from "./services/broadcast.service";
import {SocialAuthService} from "@abacritt/angularx-social-login"
import {Socket} from "ngx-socket-io";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({opacity: 0}),
        animate(500, style({opacity: 1}))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(500, style({opacity: 0}))
      ])
    ])
  ]
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'livecontent';
  configResponse: any;
  configLiveVideo: any;
  configLiveChat: any;
  config: any;
  widgetOpen: any;
  liveDataModel: LiveDataModel;
  isMobile: boolean = false;
  timeOnShow: any = false;
  screenHeight: any = 0;

  constructor(
    private _configService: ConfigService,
    private _broadcastService: BroadcastService,
  ) {
    this.liveDataModel = new LiveDataModel();
  }

  calcSize() {
    let screenHeight = this.screenHeight;
    let videoHeight = (screenHeight - 300) * 0.8;
    let productHeight = (screenHeight - 300) * 0.2;
    return {
      screenHeight: screenHeight,
      videoHeight: parseInt(String(videoHeight)),
      productHeight: parseInt(String(productHeight))
    }
  }

  ngOnInit() {


    this._configService.getConfig().subscribe((res) => {
        if (res.status >= 200 && res.status < 300) {
          this.config = res.data;
          this.setLiveModelData();
          this.configLiveVideo = this.config.liveStream;
          this.configLiveChat = {...this.configLiveVideo.liveChat, videoId: this.configLiveVideo.videoId};
          this.liveDataModel.containerSizes = this.calcSize();
          this.liveDataModel.enabled = true;
        } else {
          this.liveDataModel.enabled = false;
        }
      }
    );


    this._broadcastService.subscribe((event) => {

      if (event.type === 'cancelInterval') {
        clearInterval(this.intervalOut);
      }
      if (event.type === 'changeSize') {
        this.changeSize();
      }

      if (event.type === 'postMessage') {
        let message = {type: event.data.type, data: event.data.data};
        window.parent.postMessage(message, '*');
      }
    })

    window.addEventListener("message", (event) => {
      if (event && event.data.type) {
        switch (event.data.type) {
          case 'SCREEN_SIZE':
            console.log('SCREEN SIZE EVENT')
            this.screenHeight = event.data.data.screenHeight;
            this.liveDataModel.containerSizes = this.calcSize();
            console.log('LiveDataModel : ', this.liveDataModel);
            break;

          case 'LIVE_OPEN_CLOSE':
            this.screenHeight = event.data.data.screenHeight;
            this.liveDataModel.containerSizes = this.calcSize();
            this.liveDataModel.widgetOpen = !this.liveDataModel.widgetOpen;
            if (!this.liveDataModel.widgetOpen) {
              this._broadcastService.broadcast({type: 'postMessage', data: {type: 'LIVE_CLOSED'}})
            } else {
              this._broadcastService.broadcast({type: 'postMessage', data: {type: 'LIVE_OPEN'}})
            }
            this.changeSize();
            break;
        }
      }
    })

  }
  ngAfterViewInit() {
    let message = {type: 'APP_STARTED'};
    window.parent.postMessage(message, '*');
  }

  setLiveModelData(): void {
    let liveDataModelOnLS = localStorage.getItem('liveDataModel');
    if (liveDataModelOnLS) {
      this.liveDataModel = JSON.parse(liveDataModelOnLS);
      if (this.liveDataModel.volumen == 0) {
        this.liveDataModel.mute = 1;
      }
    } else {
      this.liveDataModel.playing = false;
    }
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.liveDataModel.isMobile = true;
      this.liveDataModel.displayActionBar = true;
    } else {
      this.liveDataModel.isMobile = false;
    }
  }


  onHover() {
    if (!this.liveDataModel.isMobile) {
      this.timeOnShow = new Date();
      this.liveDataModel.displayActionBar = true;
    }

  }

  intervalOut: any = false;

  intervalOutFn() {
    if (this.timeOnShow !== false) {
      this.intervalOut = setInterval(() => {
        var dif = ((new Date()).getTime() - this.timeOnShow.getTime()) / 1000;
        if (dif > 4) {
          this.liveDataModel.displayActionBar = false;
          this._broadcastService.broadcast({type: 'cancelInterval'});
        }
      }, 1000)
    }
  }

  onMouseLeave() {
    if (!this.liveDataModel.isMobile) {
      console.log('mouse leave');
      this.timeOnShow = new Date();
      if (this.liveDataModel.displayActionBar && !this.intervalOut) {
        this.intervalOutFn();
      } else if (!this.liveDataModel.displayActionBar && this.intervalOut) {
        clearInterval(this.intervalOut);
        this.intervalOut = false;
        this.timeOnShow = false;
      }
    }
  }

  onChangeDataEvent(value: any) {
    this.liveDataModel = value;
    localStorage.setItem('liveDataModel', JSON.stringify(this.liveDataModel));
  }


  sendMessageSize(type: string, data = null) {
    let message = {type: type, data};
    window.parent.postMessage(message, '*');
  }


  changeSize() {

    setTimeout(() => {
      let element = document.getElementById('livestream');
      // @ts-ignore
      let rect = element.getBoundingClientRect();
      let height = rect.y + rect.height;
      var width = '500px'
      if (!this.isMobile) {
        width = this.findWidthOnConfig(this.configLiveVideo.options);
        console.log('WIDTH:', width)
      }

      let data = {height, width};
      // @ts-ignore
      this.sendMessageSize('SIZE_CHANGE', data);
    }, 1)
  }

  findWidthOnConfig(a: any) {
    // @ts-ignore
    let found = a.find(e => e.key == 'width')
    if (found) {
      return found.value;
    } else {
      return '500px';
    }
  }


}
