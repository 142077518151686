<div [ngClass]="products.length >=4 ? 'product-carousel' : 'products-list'" *ngIf="liveDataModel.containerSizes.screenHeight >= 770"  >
  <div class="product-card animate__animated animate__fadeIn" *ngFor="let product of products"
       [ngStyle]="{'background-image': 'url(' + product.img + ')', 'height':liveDataModel.containerSizes.productHeight+'px'}" (click) = clickOnProduct(product) >
    <div class="description">
      <div class="name">{{product.title}}</div>
      <div class="bottom">
        <div class="price">{{product.price}}</div>
      </div>
    </div>
  </div>
</div>

<div [ngClass]="products.length >=4 ? 'product-carousel' : 'products-list'" *ngIf="liveDataModel.containerSizes.screenHeight < 770" style="position: absolute;width: 100%;">
  <div class="product-card animate__animated animate__fadeIn" *ngFor="let product of products"
       [ngStyle]="{'background-image': 'url(' + product.img + ')', 'height':'70px !important'}" (click) = clickOnProduct(product) >
    <div class="description">
      <div class="name">{{product.title}}</div>
      <div class="bottom">
        <div class="price">{{product.price}}</div>
      </div>
    </div>
  </div>
</div>


