import {SocialUser} from "@abacritt/angularx-social-login";

export class LiveDataModel {
  playing: boolean = false;
  mute: any = 0;
  volumen: number = 50;
  showProducts: boolean = false;
  like: boolean = false
  widgetOpen: boolean = false;
  livechatOpen: boolean = false;
  userLogged: boolean = false;
  displayActionBar: boolean = false;
  isMobile : boolean =false;
  isLoggedIn: boolean = false;
  userData: null = null;
  enabled: boolean = false;
  containerSizes: any = {};
  constructor() {

  }


}
