import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {BroadcastService} from "../services/broadcast.service";
import {MatSliderChange} from "@angular/material/slider";
import {Socket} from "ngx-socket-io";

@Component({
  selector: 'app-actionbar',
  templateUrl: './actionbar.component.html',
  styleUrls: ['./actionbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ActionbarComponent implements OnInit {

  @Input() liveDataModel: any;
  @Output() dataEvent = new EventEmitter<any>();
  displayVolumen: boolean = false;

  constructor(public broadCastService: BroadcastService, private socket: Socket) {
    this.socket.emit('join', 'r1');
  }


  ngOnInit(): void {
    this.liveDataModel.like = false;
  }

  clickSound() {
    this.liveDataModel.mute = !this.liveDataModel.mute;
    if (this.liveDataModel.mute) {
      this.liveDataModel.volumen = 0;
      this.broadCastService.broadcast({type: 'VOLUME', value: {volume: 0}})
    } else {
      this.liveDataModel.volumen = 50;
      this.broadCastService.broadcast({type: 'VOLUME', value: {volume: 50}})
    }
    this.sendDataEvent();
  }

  clickProducts() {
    this.liveDataModel.showProducts = !this.liveDataModel.showProducts;
    this.sendDataEvent();
    this.broadCastService.broadcast({type: 'changeSize'})
  }

  enabledLike = true;

  clickLike() {
    if (this.enabledLike) {
      this.liveDataModel.like = true;
      this.enabledLike = false;
      this.sendMessage('liked');
      setTimeout(() => {
        this.liveDataModel.like = false;
      }, 4000)
      setTimeout(() => {
        this.enabledLike = true;
      }, 10000)
    }
  }

  sendMessage(msg: string) {
    this.socket.emit('join', 'r1');

    if (this.liveDataModel.socialUser && this.liveDataModel.socialUser.photoUrl) {
      this.socket.emit('like', {img: this.liveDataModel.socialUser.photoUrl});
      console.log('mando un like');
    } else {
      this.socket.emit('like', {img: false});
      console.log('mando un like');
    }
  }

  clickLivechat() {
    this.liveDataModel.livechatOpen = !this.liveDataModel.livechatOpen;
    this.sendDataEvent();
    this.broadCastService.broadcast({type: 'changeSize'})
  }

  sendDataEvent() {
    this.dataEvent.emit(this.liveDataModel);
  }

  activeVolumenBar() {
    this.displayVolumen = true;
    setTimeout(() => {
      if (this.displayVolumen) {
        this.displayVolumen = false;
      }
    }, 4000)
  }

  changeVolume(val: MatSliderChange) {
    console.log('CHANGE VOLUMEN', val)

    this.broadCastService.broadcast({type: 'VOLUME', value: {volume: val.value}})
  }

}
