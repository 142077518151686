import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import {BroadcastService} from "../../services/broadcast.service";
import {LazyLoadScriptService} from "../../services/lazy-load-script.service";

@Component({
  selector: 'app-youtube-player',
  templateUrl: './youtube-player.component.html',
  styleUrls: ['./youtube-player.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class YoutubePlayerComponent implements OnInit, OnChanges {

  @Input() configLiveVideo: any;
  @Input() liveDataModel: any;
  @Output() dataEvent = new EventEmitter<any>();

  player: any;
  private apiLoaded: Boolean = false;
  private videoId: any = null;
  public YT: any;
  private youtubeOptions: any;
  playerId: any;


  constructor(
    public window: Window,
    private broadCastService: BroadcastService,
    private lazyLoadService: LazyLoadScriptService
  ) {

  }



  ngOnChanges(changes: SimpleChanges) {
    console.log('changes : ', changes);
    // changes.prop contains the old and the new value...
  }

  ngOnInit(): void {

    // @ts-ignore
    this.youtubeOptions = {
      videoId: this.configLiveVideo.videoId,
      playerVars: this.configLiveVideo.playerVars,
      events: {
        'onStateChange': this.onPlayerStateChange.bind(this),
        'onError': this.onPlayerError.bind(this),
        'onReady': this.onPlayerReady.bind(this),
      }
    };

    if (this.configLiveVideo.options && Array.isArray(this.configLiveVideo.options)) {
      this.configLiveVideo.options.forEach((element: { key: any; value: any; }) => {
          Object.assign(this.youtubeOptions, {
            [element.key]: element.value
          });
      });
    }

    //this.youtubeOptions.height = this.liveDataModel.containerSizes.videoHeight +'px';

    if (!document.getElementById('youtubeIframeApi')) {
      console.log('no existe script, se agrega');

      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      tag.id = 'youtubeIframeApi'
      document.body.appendChild(tag);
      // @ts-ignore
      window['onYouTubeIframeAPIReady'] = () => this.startVideo();
      this.window.addEventListener('onYouTubeIframeAPIReady', () => {
        this.startVideo();
      })
    } else {
      this.startVideo()
    }

    this.broadCastService.subscribe((event) => {
      if (event.type === 'VOLUME') {
        this.setVolumen();
      }
    })



  }

  startVideo() {
    console.log('START VIDEO FN');
    setTimeout(() => {
      // @ts-ignore
      this.player = new window['YT'].Player('player', this.youtubeOptions);
    }, 1)

  }

  isMobileRestricted = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  onPlayerReady(event: any): void {
    this.broadCastService.broadcast({type: 'postMessage', data: {type: 'LIVE_OPEN'}});
    this.broadCastService.broadcast({type: 'changeSize'});
    console.log('this.liveDataModel', this.liveDataModel);
    this.setPropertiesIfVideoWasPlayedBefore(event);


  }

  private setPropertiesIfVideoWasPlayedBefore(event: any) {
    if (this.liveDataModel.playing) {
      if (this.isMobileRestricted) {
        this.muteAndPlay(event);
      } else {
        this.retrieveVolumenAndPlay(event);
      }
      this.sendDataEvent();
    }
  }

  private retrieveVolumenAndPlay(event: any) {
    this.setVolumen()
    event.target.playVideo();
  }

  private muteAndPlay(event: any) {
    event.target.mute();
    this.liveDataModel.mute = 1;
    event.target.playVideo();
  }

  setVolumen() {
    console.log('SET VOLUMEN TO :', this.liveDataModel.volumen)
    if (this.liveDataModel.volumen != 0 && !this.liveDataModel.mute) {
      this.player.unMute()
      this.liveDataModel.mute = 0;
    } else {
      this.player.mute()
      this.liveDataModel.mute = 1;
    }
    this.player.setVolume(this.liveDataModel.volumen)
    this.sendDataEvent();
  }

  cleanTime() {
    return Math.round(this.player.getCurrentTime())
  }

  onPlayerStateChange(event: any): void {

    // @ts-ignore

    switch (event.data) {
      // @ts-ignore
      case window['YT'].PlayerState.PLAYING:
        if (this.cleanTime() == 0) {
          console.log('started ' + this.cleanTime());
        } else {
          console.log('playing ' + this.cleanTime())
        }
        this.liveDataModel.playing = true;
        this.sendDataEvent();
        break;
      // @ts-ignore
      case window['YT'].PlayerState.PAUSED:
        if (this.player.getDuration() - this.player.getCurrentTime() != 0) {
          console.log('paused' + ' @ ' + this.cleanTime());
        }
        this.liveDataModel.playing = false;
        this.sendDataEvent();
        break;
      // @ts-ignore
      case window['YT'].PlayerState.ENDED:
        console.log('ended ');
        break;
    }
  }

  onPlayerError(event: any): void {
    switch (event.data) {
      case 2:
        console.log('error : ', event)
        break;
      case 100:
        break;
      case 101 || 150:
        break;
    }
  }

  handleClick() {
    this.liveDataModel.widgetOpen = !this.liveDataModel.widgetOpen;
    this.sendDataEvent();
  }

  sendDataEvent() {
    this.dataEvent.emit(this.liveDataModel);
  }

}



