<div class="google-btn" *ngIf="!liveDataModel.isLoggedin" (click)="loginWithGoogle()">
  <div class="google-icon-wrapper" (click)="loginWithGoogle()">
    <img class="google-icon" src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"/>
  </div>
  <p class="btn-text"><b>Iniciar con Google</b></p>
</div>

<div id="contenedorLiveChat"  style="overflow: hidden" [ngStyle]="{'height': liveDataModel.isLoggedin ? '300px' : '0px'}">
  <iframe *ngIf="liveDataModel.isLoggedin" [src]="configLiveChat.iframeUrl | safeurl" width="100%" height="470" id="chat-embed" class="iframeLivechat" referrerpolicy="origin"></iframe>
</div>

