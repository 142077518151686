import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BroadcastService} from "../services/broadcast.service";

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
})
export class ProductsComponent implements OnInit {
  @Input() liveDataModel: any;
  @Input() products: any =[];
  @Output() dataEvent = new EventEmitter<any>();


  constructor(private broadCastService: BroadcastService) {
  }

  ngOnInit(): void {

  }
  clickOnProduct(product: any){
    this.broadCastService.broadcast({type:'postMessage', data : {type:'PRODUCT', data:product}})
  }

  sendDataEvent() {
    this.dataEvent.emit(this.liveDataModel);
  }
}
