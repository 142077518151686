import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {YoutubePlayerComponent} from "./youtube-player/youtube-player.component";
import {LiveDataModel} from "../LiveDataModel";
import {BroadcastService} from "../services/broadcast.service";
import {Socket} from 'ngx-socket-io';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-livevideo',
  templateUrl: './livevideo.component.html',
  styleUrls: ['./livevideo.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class LivevideoComponent implements OnInit {

  @Input() configLiveVideo: any;
  @Input() liveDataModel: any;
  @Output() liveVideoStatus: EventEmitter<boolean> = new EventEmitter();
  @Output() dataEvent = new EventEmitter<any>();

  constructor(private broadCastService: BroadcastService, private socket: Socket) {
  }

  likes: any = [];

  ngOnInit(): void {
    if (this.configLiveVideo.platform === 'youtube') {
      this.configLiveVideo.videoPlatform = 'youtube'
    }
    console.log('this.configLiveVideo', this.configLiveVideo);
    this.sendDataEvent();
    this.socket.on('like', (data: any) => {
      data.state = true;
      this.likes.push(data);
      setTimeout(() => {
        data.state = false
      }, 4000)
    });
    setInterval(() => {
      this.likes = this.likes.filter(function (like: any) {
        return like.state !== false
      },1000)
    })
  }


  sendDataEvent() {
    this.dataEvent.emit(this.liveDataModel);
  }

  onChangeDataEvent(value: any) {
    this.liveDataModel = value;
    this.dataEvent.emit(this.liveDataModel)
    console.log('LIVE DATA MODEL', this.liveDataModel);
  }

}
