import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {BroadcastService} from "../services/broadcast.service";


@Component({
  selector: 'app-livechat-button',
  templateUrl: './livechat-button.component.html',
  styleUrls: ['./livechat-button.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LivechatButtonComponent implements OnInit {

  @Input() liveDataModel: any;
  @Output() dataEvent = new EventEmitter<any>();

  constructor() {

  }


  ngOnInit(): void {


  }


  handleClick() {
    this.liveDataModel.widgetOpen = !this.liveDataModel.widgetOpen;
    this.sendDataEvent();
  }

  sendDataEvent(){
    this.dataEvent.emit(this.liveDataModel);
  }



}
