<section *ngIf="liveDataModel && configLiveVideo && liveDataModel.widgetOpen">
  <app-livevideo (mouseover)="onHover()" (mouseout)="onMouseLeave()" [configLiveVideo]="configLiveVideo"
                 [liveDataModel]="liveDataModel" (dataEvent)="onChangeDataEvent($event)"></app-livevideo>

  <app-actionbar [@fadeInOut] *ngIf="!liveDataModel.isMobile && liveDataModel.displayActionBar" (mouseover)="onHover()" (mouseout)="onMouseLeave()"
                 [liveDataModel]="liveDataModel" (dataEvent)="onChangeDataEvent($event)"></app-actionbar>
  <app-actionbar *ngIf="liveDataModel.isMobile" [liveDataModel]="liveDataModel"
                 (dataEvent)="onChangeDataEvent($event)"></app-actionbar>


  <app-products [@fadeInOut] *ngIf="configLiveVideo && configLiveVideo.products" [hidden]="!liveDataModel.showProducts"
                [liveDataModel]="liveDataModel" [products]="configLiveVideo.products"
                (dataEvent)="onChangeDataEvent($event)"></app-products>
  <app-livechat [@fadeInOut] *ngIf="liveDataModel" [hidden]="liveDataModel.livechatOpen !== true " [liveDataModel]="liveDataModel"
                [configLiveChat]="configLiveChat" (dataEvent)="onChangeDataEvent($event)"></app-livechat>
</section>
<app-livechat-button [liveDataModel]="liveDataModel" *ngIf="liveDataModel.enabled && !liveDataModel.widgetOpen"
                     (dataEvent)="onChangeDataEvent($event)">
</app-livechat-button>
